import React from "react";
import styles from "./Loader.module.css";
const Loader = () => {
  return (
    <div className={styles["load-main-cont"]}>
      <div className={styles["loader-container"]}>
        <div className={styles["loader"]}></div>
      </div>
    </div>
  );
};

export default Loader;
