import React from "react";

const VerticalLine = () => {
  return (
    <div className='vertical-container '>
      <div className='vertical'></div>
    </div>
  );
};

export default VerticalLine;
